import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import SecondaryHeader from "./SecondaryHeader";


const Layout = ({ children }) => {
  return (
    <div className="bg-black font-Poppins">
      <Header siteTitle={"Lazar Law"} />
      <SecondaryHeader siteTitle={"Lazar Law"} />
      <div
      >
        <main className="w-full">{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
