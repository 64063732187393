import * as React from "react";
import { useWindowSize } from "../hooks/useWindowSize.js";
import FacebookSVG from "../images/facebook.js";
import {
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";

const ContactSection = () => {
  const windowSize = useWindowSize();

  if (windowSize.width > 700)
    return (
      <div id="contact" className={`${"bg-white py-20"}`}>
        <div className="flex w-full justify-center">
          <div className="flex flex-row space-x-4">
            <div className="h-1 w-32 bg-gold my-auto"></div>
            <div className="text-5xl text-center text-gold font-bold">
              Contact
            </div>
            <div className="h-1 w-32 bg-gold my-auto"></div>
          </div>
        </div>
        <div className="flex justify-between mt-8 w-full">
          <div className="flex flex-row mt-10 mb-10 w-full space-x-20">
          <iframe
              className="w-6/12"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d712.1881334923581!2d26.078240529224853!3d44.43823819869388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ffe02b60e68b%3A0x610310220e5d9ee6!2sCabinet%20de%20avocat%20Carmen%20Lazar!5e0!3m2!1sen!2sro!4v1639586865551!5m2!1sen!2sro"
              height={500}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            ></iframe>
            <div className="flex flex-col space-y-24 mt-2 mb-2">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.google.com/maps/place/Strada+%C8%98tirbei+Vod%C4%83+117,+Bucure%C8%99ti/@44.4383091,26.0765988,17z/data=!3m1!4b1!4m5!3m4!1s0x40b1ff592d33a50b:0xbe7e388204dbe4dc!8m2!3d44.4383053!4d26.0787875"
                className="flex flex-row space-x-6 text-2xl my-auto"
              >
                <LocationMarkerIcon className="h-12 w-12 text-gold" />
                <div className="my-auto">
                  <div>Str. Știrbei Vodă nr. 117, Sector 5, București</div>
                </div>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://facebook.com/cabinetavocatura.carmenlazar"
                className="flex flex-row space-x-6 text-2xl my-auto"
              >
                <div className="my-auto">
                  <FacebookSVG size={12} />
                </div>
                <div className="my-auto">
                  <div>Avocat Carmen Lazar</div>
                </div>
              </a>
              <a
                rel="noreferrer"
                href="tel: +4-0722-726-763"
                className="flex flex-row space-x-6 text-2xl my-auto"
              >
                <div className="my-auto">
                  <PhoneIcon className="h-12 w-12 text-gold" />
                </div>
                <div className="my-auto">
                  <div>+4 (0722) 726 763</div>{" "}
                </div>
              </a>
              <a
                rel="noreferrer"
                href="mailto: contact@carmenlazar.ro"
                className="flex flex-row space-x-6 text-2xl my-auto"
              >
                <div className="my-auto">
                  <MailIcon className="h-12 w-12 text-gold" />
                </div>
                <div className="my-auto">
                  <div>contact@carmenlazar.ro</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div id="contact" className={`${"bg-white pb-10"}`}>
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full space-y-10">
            <iframe
              className="w-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d712.1881334923581!2d26.078240529224853!3d44.43823819869388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ffe02b60e68b%3A0x610310220e5d9ee6!2sCabinet%20de%20avocat%20Carmen%20Lazar!5e0!3m2!1sen!2sro!4v1639586865551!5m2!1sen!2sro"
              height={500}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
            ></iframe>
            <div className="flex w-full justify-center">
            <div className="flex flex-row mt-2 mb-2 px-4 space-x-10">
              <div className="flex flex-col space-y-6">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.google.com/maps/place/Strada+%C8%98tirbei+Vod%C4%83+117,+Bucure%C8%99ti/@44.4383091,26.0765988,17z/data=!3m1!4b1!4m5!3m4!1s0x40b1ff592d33a50b:0xbe7e388204dbe4dc!8m2!3d44.4383053!4d26.0787875"
                  className="flex flex-row space-x-4 text-xs my-auto"
                >
                  <div className="my-auto">
                    <LocationMarkerIcon className="h-6 w-6 text-gold" />
                  </div>
                  <div className="flex flex-col my-auto">
                    <div>Str. Știrbei Vodă 117</div>
                  </div>
                </a>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://facebook.com/cabinetavocatura.carmenlazar"
                  className="flex flex-row space-x-4 text-xs my-auto"
                >
                  <div className="my-auto">
                    <FacebookSVG size={6} />
                  </div>
                  <div className="my-auto">
                    <div>Avocat Carmen Lazar</div>
                  </div>
                </a>
              </div>
              <div className="flex flex-col space-y-6">
                {" "}
                <a
                  rel="noreferrer"
                  href="tel: +4-0722-726-763"
                  className="flex flex-row space-x-4 text-xs my-auto"
                >
                  <div className="my-auto">
                    <PhoneIcon className="h-6 w-6 text-gold" />
                  </div>
                  <div className="my-auto">
                    <div>0722 726 763</div>{" "}
                  </div>
                </a>
                <a
                  rel="noreferrer"
                  href="mailto: contact@carmenlazar.ro"
                  className="flex flex-row space-x-4 text-xs my-auto"
                >
                  <div className="my-auto">
                    <MailIcon className="h-6 w-6 text-gold" />
                  </div>
                  <div className="flex flex-col my-auto">
                    <div> contact </div>
                    <div> @carmenlazar.ro </div>
                  </div>
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ContactSection;
