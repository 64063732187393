import * as React from "react"
import { PhoneIcon, MailIcon, CalendarIcon, MenuIcon } from "@heroicons/react/solid"
import { XIcon } from "@heroicons/react/solid";
import { AnimatePresence, motion } from "framer-motion";
import WhatsappSVG from "../images/whatsapp"
import logo from "../images/logo.png"

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); 
  return windowSize;
}

const headerIn = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
    y: 0,
  },
  exit: {
    y: -20,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};


const Header = ({ siteTitle }) => {
  const windowSize = useWindowSize()
  const [showMobileNav, setShowMobileNav] = React.useState(false)

  return <header className={`absolute top-0 z-40 w-full`}>
    <div className="relative">
    <AnimatePresence>
            {showMobileNav && (
              <motion.div
                key="mobile-nav"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={headerIn}
                className="fixed w-full top-0 z-50"
              >
                <div className="flex justify-center w-full bg-black">
                  <div className="flex flex-col w-10/12 -space-y-10">
                    <div className="flex justify-between px-4 py-8 mt-2">
                      <div className="bg-black text-white text-xl invisible">
                        Lazar Law
                      </div>
                      <div className="my-auto">
                        <button
                          onClick={() => setShowMobileNav(false)}
                          className=""
                        >
                          <XIcon className="text-white h-8 w-8 text-white" />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-center text-center pb-10">
                    <div className="flex flex-col space-y-10">
                      <a
                        className={
                          (window.location.pathname === "/despre"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/"
                      >
                        {" "}
                        Despre{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/drept-penal"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/drept-penal"
                      >
                        {" "}
                        Drept Penal{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/drept-civil"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/drept-civil"
                      >
                        {" "}
                        Drept Civil{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/dreptul-familiei"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/dreptul-familiei"
                      >
                        {" "}
                        Dreptul Familiei{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/diaspora"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/diaspora"
                      >
                        {" "}
                        Diaspora{" "}
                      </a>
                      <a
                        className={"text-gray-300 text-lg hover:text-white font-light"}
                        href="https://blog.carmenlazar.ro"
                      >
                        {" "}
                        Blog{" "}
                      </a>
                    </div>
                  </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
    <div 
      id={"primary-feature"} 
      className="flex flex-col h-32 w-full">
      {windowSize.width > 1200 && <div className={`flex justify-center bg-top-bar bg-opacity-80 invisible md:visible`}>
        <div className="text-white px-20 w-full md:w-11/12">
          <div className="flex justify-between">
            <div className="flex flex-row space-x-8 py-2 my-auto">
              <button>
              <a href="tel: +4-0722-726-763" className="flex flex-row py-3 space-x-2 transition duration-500 ease-in-out hover:text-gold">
                <PhoneIcon className="h-4 w-5 my-auto" />
                <span> +4 (0722) 726 763 </span>
              </a>
              </button>
              <button className="transition duration-500 ease-in-out hover:text-gold">
              <a href="mailto: contact@carmenlazar.ro" className="flex flex-row py-3 space-x-2">
                <MailIcon className="h-4 w-5 my-auto" />
                <span> contact@carmenlazar.ro </span>
              </a>
              </button>
              <div className="flex flex-row py-3 space-x-2">
                <CalendarIcon className="h-4 w-5 my-auto" />
                <span> Lu - Vi 09:00-17:00 </span>
              </div>
            </div>
            <div>
          
            <a href="  https://wa.me/+40722726763?text=Buna ziua, va contactez in legatura cu ..." className="inline-flex px-7 py-4 h-full w-72 bg-green-500 invisible lg:visible my-auto">
              {/* <a href="whatsapp://send?text=MESSAGE&phone=+40735181450&abid=+40735181450" className="inline-flex px-7 py-4 h-full w-72 bg-green-500 invisible lg:visible my-auto">  */}
                <div className="flex flex-row w-full space-x-5 my-auto ">
                  <div className="w-10/12 uppercase font-semibold"> Contacteaza-ne </div>
                  <div className="my-auto w-2/12"> <WhatsappSVG /> </div>
                </div>
              </a>
            </div>
          </div>
          <div>
          </div>
          <div>
          </div>
        </div>
      </div>}
      <div>
        <div className={`flex justify-center w-full ${windowSize.width < 900 && " bg-top-bar"}`}>
          <div className={`flex flex-row justify-between w-10/12 px-4 ${windowSize.width > 900 ? "py-10" : "py-6"} mt-2 trasition ease-in-out duration-1000`}>
            <div className="flex justify-center text-xl font-semibold text-white">
              <a className="my-auto" href="/"> <img className="my-auto h-7" alt="logo" src={logo} /> </a>
            </div>
            {windowSize.width > 900 && <div className="flex flex-row space-x-6 xl:space-x-8 invisible lg:visible">
              <a
                className={(window.location.pathname === "/despre" ? "text-white" : "text-gray-300") + " text-lg  hover:text-white font-light"}
                href="/despre"
              >
                {" "}
                Despre{" "}
              </a>
              <a
                className={(window.location.pathname === "/drept-penal" ? "text-white" : "text-gray-300") + " text-lg  hover:text-white font-light"}
                href="/drept-penal"
              >
                {" "}
                Drept Penal{" "}
              </a>
              <a
                className={(window.location.pathname === "/drept-civil" ? "text-white" : "text-gray-300") + " text-lg  hover:text-white font-light"}
                href="/drept-civil"
              >
                {" "}
                Drept Civil{" "}
              </a>
              <a
                className={(window.location.pathname === "/dreptul-familiei" ? "text-white" : "text-gray-300") + " text-lg  hover:text-white font-light"}
                href="/dreptul-familiei"
              >
                {" "}
                Dreptul Familiei{" "}
              </a>
              <a
                className={(window.location.pathname === "/diaspora" ? "text-white" : "text-gray-300") + " text-lg  hover:text-white font-light"}
                href="/diaspora"
              >
                {" "}
                Diaspora{" "}
              </a>
              <a
                className={"text-gray-300 text-lg hover:text-white font-light"}
                href="https://blog.carmenlazar.ro"
              >
                {" "}
                Blog{" "}
              </a>
            </div>}
            {windowSize.width < 900 && <div className="my-auto">
            <button onClick={() => setShowMobileNav(true)} className="mt-1.5">
              <MenuIcon className="text-white h-8 w-8 text-white" />
            </button>
            </div>}
          </div>
        </div>

      </div>
    </div>
    </div>
  </header>
}

export default Header
