import { motion, AnimatePresence } from "framer-motion";
import * as React from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import logo from "../images/logo.png";

const headerIn = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
    y: 0,
  },
  exit: {
    y: -20,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

// const MobileNav = ({ active }) => {
//   return (
//     <AnimatePresence>
//       {active && (
//         <motion.div
//           key="mobile-nav"
//           initial="hidden"
//           animate="visible"
//           exit="exit"
//           variants={headerIn}
//           className="fixed w-full top-0 z-50"
//         >
//           <div className="flex justify-center w-full bg-black">
//             <div className="flex flex-col w-10/12">
//               <div className="flex justify-between  px-4 py-10 mt-2">
//                 <div className="bg-black text-white text-xl">Lazar Law</div>
//                 <div className="my-auto">
//                   <button
//                     // onClick={() => setShowMobileNav(true)}
//                     className="md:invisible"
//                   >
//                     <XIcon className="text-white h-8 w-8 text-white" />
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// };

const SecondaryHeader = ({ siteTitle }) => {
  const [animateHeader, setAnimateHeader] = React.useState(false);
  const [showMobileNav, setShowMobileNav] = React.useState(false);
  const windowSize = useWindowSize();
  React.useEffect(() => {
    const listener = () => {
      if (window.scrollY >= 500) {
        setAnimateHeader(true);
      } else setAnimateHeader(false);
    };
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <AnimatePresence>
      {animateHeader && (
        <motion.div
          key="secondary-header"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={headerIn}
          className="fixed w-full top-0 z-40"
        >
          <AnimatePresence>
            {showMobileNav && (
              <motion.div
                key="mobile-nav"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={headerIn}
                className="fixed w-full top-0 z-50"
              >
                <div className="flex justify-center w-full bg-black">
                  <div className="flex flex-col w-10/12 -space-y-10">
                    <div className="flex justify-between px-4 py-10 mt-2">
                      <div className="bg-black text-white text-xl invisible">
                        Lazar Law
                      </div>
                      <div className="my-auto">
                        <button
                          onClick={() => setShowMobileNav(false)}
                          className=""
                        >
                          <XIcon className="text-white h-8 w-8 text-white" />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-center text-center pb-10">
                    <div className="flex flex-col space-y-10">
                      <a
                        className={
                          (window.location.pathname === "/despre"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/despre"
                      >
                        {" "}
                        Despre{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/drept-penal"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/drept-penal"
                      >
                        {" "}
                        Drept Penal{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/drept-civil"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/drept-civil"
                      >
                        {" "}
                        Drept Civil{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/dreptul-familiei"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/dreptul-familiei"
                      >
                        {" "}
                        Dreptul Familiei{" "}
                      </a>
                      <a
                        className={
                          (window.location.pathname === "/diaspora"
                            ? "text-white"
                            : "text-gray-300") +
                          " text-lg  hover:text-white font-light"
                        }
                        href="/diaspora"
                      >
                        {" "}
                        Diaspora{" "}
                      </a>
                    </div>
                  </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="flex justify-center w-full bg-top-bar">
            <div
              className={`flex flex-row justify-between w-10/12 px-4 py-10 mt-2 trasition ease-in-out duration-1000`}
            >
              <div className="flex justify-center text-xl font-semibold text-white">
                <a href="/"> <img className="my-auto h-7" alt="logo" src={logo} /> </a>
              </div>
              {windowSize.width > 900 && (
                <div className="flex flex-row space-x-6 xl:space-x-8 invisible lg:visible">
                  <a
                    className={
                      (window.location.pathname === "/despre"
                        ? "text-white"
                        : "text-gray-300") +
                      " text-lg  hover:text-white font-light"
                    }
                    href="/despre"
                  >
                    {" "}
                    Despre{" "}
                  </a>
                  <a
                    className={
                      (window.location.pathname === "/drept-penal"
                        ? "text-white"
                        : "text-gray-300") +
                      " text-lg  hover:text-white font-light"
                    }
                    href="/drept-penal"
                  >
                    {" "}
                    Drept Penal{" "}
                  </a>
                  <a
                    className={
                      (window.location.pathname === "/drept-civil"
                        ? "text-white"
                        : "text-gray-300") +
                      " text-lg  hover:text-white font-light"
                    }
                    href="/drept-civil"
                  >
                    {" "}
                    Drept Civil{" "}
                  </a>
                  <a
                    className={
                      (window.location.pathname === "/dreptul-familiei"
                        ? "text-white"
                        : "text-gray-300") +
                      " text-lg  hover:text-white font-light"
                    }
                    href="/dreptul-familiei"
                  >
                    {" "}
                    Dreptul Familiei{" "}
                  </a>
                  <a
                    className={
                      (window.location.pathname === "/diaspora"
                        ? "text-white"
                        : "text-gray-300") +
                      " text-lg  hover:text-white font-light"
                    }
                    href="/diaspora"
                  >
                    {" "}
                    Diaspora{" "}
                  </a>
                </div>
              )}
              {windowSize.width < 900 && (
                <div className="my-auto">
                  <button
                    onClick={() => setShowMobileNav(true)}
                    className=""
                  >
                    <MenuIcon className="text-white h-8 w-8 text-white" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SecondaryHeader;
