import * as React from "react"

function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState({
      width: undefined,
      height: undefined,
    });
    React.useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []); 
    return windowSize;
}

const Footer = () => {
    const windowSize = useWindowSize()

    if(windowSize.width > 1100)
     return <footer className="flex flex-col">
        <div className="flex justify-center bg-footer py-14">
            <div className="flex flex-row w-10/12 space-x-1">
                <div className="flex flex-col w-9/12 px-10 space-y-3">
                    <div className="text-2xl text-white">
                        Despre
                    </div>
                    <div className="text-gray-300 font-light">
                        <p className="w-full">
                        Cabinetul de avocatură Carmen Lazăr a fost înființat în anul 1997, avocatul titular fiind înscris în Baroul București din anul 1992.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col px-10 w-9/12 space-y-3">
                    <div className="text-2xl text-white">
                        Date Contact
                    </div>
                    <div className="text-gray-300 font-light">
                        <p className="w-full">
                            Știrbei Vodă nr. 117
                        </p>
                        <p className="w-full">
                            Sector 1
                        </p>
                        <p className="w-full">
                            Bucuresti
                        </p>
                    </div>
                    <div className="text-gray-300 font-light">
                        <a href="tel: +4-0722-726-763" className="w-full text-white">
                            +4 (0722) 726 763
                        </a>
                        <br></br>
                        <a href="mailto: contact@carmenlazar.ro" className="w-full text-white">
                            contact@carmenlazar.ro
                        </a>
                    </div>
                </div>
                <div className="flex flex-col px-10 w-9/12 space-y-4">
                    <div className="text-2xl text-white">
                        Domenii Specialitate
                    </div>
                    <div className="flex flex-col text-gray-300 space-y-1 mt-1 font-light">
                        <a href="/drept-penal">
                            Drept Penal
                        </a>
                        <a href="/drept-civil">
                            Drept Civil
                        </a>
                        <a href="/dreptul-familiei">
                            Dreptul Familiei
                        </a>
                        <a href="/diaspora">
                            Diaspora
                        </a>
                    </div>
                </div>
                <div className="flex flex-col px-10 w-9/12 space-y-4 font-light">
                    <div className="text-xl text-white invisible">
                        _
                    </div>
                    <div className="flex text-gray-300 space-y-1 mt-1">
                       <p>
                       O consultație juridică este utilă în găsirea celei mai bune soluții pentru problema dvs, evitând timpul de așteptare și costurile suplimentare ale unui litigiu.
                       </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex justify-center w-full bg-black">
            <div className="flex flex-row justify-center w-10/12 px-10 py-5">
                <div className="flex justify-center font-light text-white">
                    Copyright &copy; Cabinet Avocatura Carmen Lazar. Siguranță și Profesionalism din 1992.
                </div>
            </div>
        </div>
    </footer>
    else
        return <footer className="flex flex-col">
        <div className="flex justify-center bg-footer py-14">
            <div className="flex flex-col space-y-10">
                <div className="flex flex-col px-8 space-y-3">
                    <div className="text-2xl text-white">
                        Despre
                    </div>
                    <div className="text-gray-300 font-light">
                        <p className="w-full">
                            Cabinetul de avocatură Carmen Lazăr a fost înființat în anul 1997, avocatul titular fiind înscris în Baroul București din anul 1992.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col px-8 space-y-3">
                    <div className="text-2xl text-white">
                        Date Contact
                    </div>
                    <div className="flex justify-between">
                        <div className="flex flex-col text-gray-300 font-light space-y-2">
                            <p className="">
                                Știrbei Vodă nr. 117
                            </p>
                            <p className="">
                                Sector 1
                            </p>
                            <p className="">
                                Bucuresti
                            </p>
                        </div>
                        <div className="flex flex-col text-gray-300 font-light space-y-2 break-link w-32">
                            <a href="tel: +4-0722-726-763" className="text-white">
                                0722 726 763
                            </a>
                            <a href="mailto: contact@carmenlazar.ro" className="text-white break-link">
                                contact@
                            </a>
                            <a href="mailto: contact@carmenlazar.ro" className="text-white break-link">
                                carmenlazar.ro
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col px-8 space-y-3">
                    <div className="text-2xl text-white">
                        Domenii Specialitate
                    </div>
                    <div className={`flex justify-between`}>
                        <div className="flex flex-col text-gray-300 space-y-2 mt-1 font-light">
                            <a href="/drept-penal">
                                Drept Penal
                            </a>
                            <a href="/drept-civil">
                                Drept Civil
                            </a>
                        </div>
                        <div className="flex flex-col text-gray-300 space-y-2 mt-1 font-light w-32">
                            <a className="break-link" href="/dreptul-familiei">
                                Dreptul Familiei
                            </a>
                            <a className="break-link" href="/diaspora">
                                Diaspora
                            </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col px-8 font-light">
                    <div className="text-xl text-white invisible">
                        _
                    </div>
                    <div className="flex text-gray-300">
                       <p>
                       O consultație juridică este utilă în găsirea celei mai bune soluții pentru problema dvs, evitând timpul de așteptare și costurile suplimentare ale unui litigiu.
                       </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex justify-center w-full bg-black">
            <div className="flex flex-row justify-center px-10 py-5">
                <div className="flex justify-center font-light text-white text-center text-xs">
                    <div className="flex flex-col"> <div> Copyright &copy; Cabinet Avocatura Carmen Lazar. </div> <div> Siguranță și Profesionalism din 1992. </div> </div>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer
